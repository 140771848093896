import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Footer } from '../components/footer';
import { Layout } from '../components/layout';
import { PageHeader } from '../components/page-header';
import * as css from './index.module.css';

const IndexPage = ({ data }) => {
  const imageMap = data.allFile.edges.reduce((accumulator, edge) => {
    accumulator[edge.node.name] = edge.node.childImageSharp.gatsbyImageData;
    return accumulator;
  }, {});

  return (
    <Layout>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <link rel="me" href="https://pixelfed.social/dandean" />
      </Helmet>
      <PageHeader
        variant="home"
        title="Dan Dean"
        subtitle="Software, Music, and Clay"
      />
      <ul className={css.postsList}>
        {data.allMarkdownRemark.edges.map(({ node }) => {
          return (
            <li key={node.id}>
              <Link to={node.fields.slug}>
                {node.frontmatter.image ? (
                  <GatsbyImage
                    image={imageMap[node.frontmatter.image]}
                    alt=""
                  />
                ) : null}
                <div className={css.postTitle}>{node.frontmatter.title}</div>
              </Link>
            </li>
          );
        })}
      </ul>
      <Footer />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { relativePath: { glob: "img/*.png" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(
              quality: 50
              width: 600
              height: 200
              layout: CONSTRAINED
            )
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { date: { ne: null } } }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      totalCount
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            image
          }
        }
      }
    }
  }
`;
